import {
  AfterViewChecked,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { AssetsService } from '@callrail/looky/ui';
import { LocationService } from '@callrail/looky/util';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-angular';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Subscription } from 'rxjs';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabularies({
  en: {
    'Back to Sign In': 'Cancel',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      'Password must include a minimum of 12 characters',
    'Your passwords must match':
      "Password confirmation doesn't match password.",
    'Password does not conform to policy: Password not long enough':
      'Password must include a minimum of 12 characters',
    'Password does not conform to policy: Password must have uppercase characters':
      'Password must include 1 uppercase character',
    'Password does not conform to policy: Password must have lowercase characters':
      'Password must include 1 lowercase character',
    'Password does not conform to policy: Password must have numeric characters':
      'Password must include 1 number',
    'Confirm TOTP Code': 'Confirm authenticator app code',
    'Confirm SMS Code': 'Confirm SMS code',
    'Code *': 'Code',
  },
});

@Component({
  selector: 'anbs-amplify-form',
  templateUrl: 'amplify-form.component.html',
  styleUrls: ['amplify-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmplifyFormComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() redirectType = 'auth';

  public remember = false;
  public initialState!: string;
  public socialProviders: string[] = [];
  public showFreeTrial = false;
  public email: string;
  public setEmail = false;

  public formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: ' ',
        isRequired: true,
        label: 'Email',
      },
      password: {
        labelHidden: false,
        isRequired: true,
        label: 'Your password',
        placeholder: ' ',
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        placeholder: ' ',
        isRequired: true,
        label: 'Email',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        placeholder: ' ',
        isRequired: true,
        label: 'Code (from your email)',
      },
      password: {
        labelHidden: false,
        placeholder: ' ',
        isRequired: true,
        label: 'Choose a password',
      },
      confirm_password: {
        labelHidden: false,
        placeholder: ' ',
        isRequired: true,
        label: 'Confirm password',
      },
    },
  };

  private crRegex = /\..*callrail\.com/;
  private queryParams$: Subscription;

  constructor(
    public authenticator: AuthenticatorService,
    public assetsService: AssetsService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.queryParams$ = this.route.queryParams.subscribe((params: Params) => {
      this.initialState = (params?.state as string) || 'signIn';
      if (params?.email) {
        this.email = params?.email;
        this.setEmail = true;
      }
    });

    if (this.locationService.host().match(this.crRegex)) {
      this.socialProviders = ['apple', 'google'];
      this.showFreeTrial = true;
    } else {
      this.socialProviders = [];
      this.showFreeTrial = false;
    }
  }

  public ngOnDestroy(): void {
    this.queryParams$.unsubscribe();
  }

  focusOnPasswordField() {
    const passwordFields = this.elementRef.nativeElement.querySelectorAll(
      '.amplify-input[name="password"]'
    );

    if (passwordFields && passwordFields.length > 0) {
      const passwordField = passwordFields[0];
      passwordField.focus();
    }
  }

  setEmailFieldValue() {
    // No good way to set an initial value with the Amplify component.
    const emailField = this.elementRef.nativeElement.querySelector(
      '.amplify-input[name="username"]'
    );

    if (emailField) {
      emailField.value = this.email;
      this.focusOnPasswordField();
      this.setEmail = false;
    }
  }

  ngAfterViewChecked(): void {
    if (this.setEmail) {
      this.setEmailFieldValue();
    }
  }

  public toggleRemember() {
    this.remember = !this.remember;
  }
}
